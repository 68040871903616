import { Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Switch, Typography } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { TipButton } from "../misc/InternalFeatures";
import { Add, Close, Delete, Refresh, Save } from "@mui/icons-material";
import { useRef, useState } from "react";
import { useSnackbar } from "notistack";

const toolGroupStyle = {
    display: "flex", justifyContent: "space-between", flexWrap: "wrap",
}

export const toolbarSlotProps = {
    filterPanel: {
        sx: {
            "& .MuiDataGrid-panelContent .MuiDataGrid-filterForm": {
                flexWrap: "wrap"
            }
        }
    }
}
/**
 * @typedef {{
*  userConfig?: {config: import("./persistent/PersistentDataGridPro").UserConfigDataGrid},
*  onPassiveFilter?: (checked: boolean) => void,
*  onExpandList?: (checked: boolean) => void,
*  fileName?: string,
*  text?: string,
*  onClick?: () => void,
*  getRowsToExport?: (params: any) => any[],
*  hideButton?: boolean,
*  customElement?: import("react").ReactNode,
* }} CustomToolbarProps
*/

/**
* 
* @param {CustomToolbarProps} props 
* @returns 
*/
export default function CustomToolbar(props) {
    const [saveAnchor, setSaveAnchor] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const configClearSnackbarKey = useRef(null);

    const handleClearUserConfig = () => {
        if (props.userConfig && props.userConfig.config.dataGridInitialState) {
            props.userConfig.config = { ...props.userConfig.config, dataGridInitialState: undefined };
            if (!props.userConfig.config.dataGridInitialState) {
                configClearSnackbarKey.current = enqueueSnackbar("Taulukon asetukset poistettu",
                    {
                        variant: "success",
                        action: () => <FormGroup row sx={{ gap: 1 }}>
                            <Button
                                sx={{ color: "white", borderColor: "#FFFFFF99", ":hover": { borderColor: "#FFFFFFFF" } }}
                                onClick={() => window.location.reload()}
                                startIcon={<Refresh />} variant="outlined" size="small">
                                Päivitä sivu
                            </Button>
                            <IconButton onClick={() => closeSnackbar(configClearSnackbarKey.current)} size="small"><Close fontSize="small" /></IconButton>
                        </FormGroup>,
                        persist: true,
                        preventDuplicate: true,
                    })
            } else {
                enqueueSnackbar("Taulukon asetusten poistaminen epäonnistui", { variant: "error" });
            }
        }
    }

    return (
        <GridToolbarContainer sx={{
            justifyContent: "space-between",
            '& .MuiButton-root': {
                color: 'text.primary',
                '& .MuiInput-root': {
                    color: 'black', mr: 2,
                    '& .MuiTouchRipple-root': {
                        color: 'black'
                    },
                }
            }
        }}>

            <Box sx={{ ...toolGroupStyle, width: 530 }}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarQuickFilter />
            </Box>

            {props.customElement && props.customElement}

            {
                props.onPassiveFilter &&
                <Box sx={{ ...toolGroupStyle }}>
                    <FormControlLabel
                        control={<Switch
                            checked={props.passiveFilter}
                            onChange={(e, c) => { props.onPassiveFilter(c) }}
                        />}
                        label="Näytä passiiviset"
                    />
                </Box>
            }
            {
                props.onExpandList &&
                <Box sx={{ ...toolGroupStyle }}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={props.expandList}
                            onChange={(e, c) => { props.onExpandList(c) }}
                        />}
                        label="Laajenna lista"
                    />
                </Box>
            }

            <Box sx={{ ...toolGroupStyle, width: 230 }}>
                <GridToolbarExport
                    csvOptions={{
                        delimiter: ";",
                        fileName: props.fileName || "Logitar.csv",
                        utf8WithBom: true,
                        allColumns: true,
                        getRowsToExport: props.getRowsToExport || undefined,
                    }}
                    printOptions={{disableToolbarButton: !!props.hidePrinting}}
                />
                {
                    (props.userConfig && props.userConfig.config.dataGridInitialState) &&
                    <Box sx={{ ...toolGroupStyle }}>
                        <IconButton size="small" onClick={(e) => setSaveAnchor(e.target)}><Save fontSize="small" /></IconButton>
                        <Menu anchorEl={saveAnchor} open={saveAnchor != null} onClose={() => setSaveAnchor(null)}>
                            <Typography sx={{ px: 2, pb: 0.75, fontSize: "0.75rem" }}>Taulukon asetukset tallentuvat automaattisesti</Typography>
                            <MenuItem onClick={() => {
                                handleClearUserConfig();
                                setSaveAnchor(null);
                            }}>
                                <ListItemIcon><Delete /></ListItemIcon>
                                <ListItemText>Poista taulukon asetukset</ListItemText>
                            </MenuItem>
                        </Menu>
                    </Box>
                }
                {
                    !props.hideButton &&
                    <Box>
                        <TipButton size="small" variant="contained" icon={Add} onClick={() => props.onClick()} title={props.text} style={{ color: "white" }}></TipButton>
                    </Box>
                }
            </Box>

        </GridToolbarContainer>
    )
}
