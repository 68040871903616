import { Box, Checkbox } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import LogitarApi from '../api/LogitarApi'
import CustomToolbar, { toolbarSlotProps } from '../components/CustomToolbar'
import VehicleEdit from '../components/VehicleEdit'
import { localeText } from '../misc/LocaleText'
import { contentBoxStyle } from '../styles/styles'
import PersistentDataGridPro from '../components/persistent/PersistentDataGridPro'
import { useUserConfig } from '../misc/UserConfig'


export const logitarVehicleTypes = ["Vetoauto", "Perävaunu", "Pyöräkuormaaja", "Pakettiauto", "Passiivi"]

/**
 * @brief Orders vehicles array by group, id. Use variant 'vehicles' if object contains "id", "group". Use variant 'planning' if array type is GetPlanningRow[]
 * @param {object[]|import('../api/LogitarApiTypes').GetPlanningRow[]} vehicles 
 * @param {'vehicles'|'planning'} variant 
 * @returns 
 */
export function vehicleGroupSorter(vehicles, variant = 'vehicles') {

    switch (variant) {
        case 'planning':
            /** @type {GetPlanningRow[]} */
            const _veh = vehicles;

            return _veh.sort((a, b) => {
                // Nulls last
                if (a.vehicle.group == null && b.vehicle.group != null) {
                    return 1;
                }
                else if (a.vehicle.group != null && b.vehicle.group == null) {
                    return -1;
                }
                else if (a.vehicle.group != null && b.vehicle.group != null) {
                    // Order by group
                    const gOrder = a.vehicle.group.localeCompare(b.vehicle.group);
                    if (gOrder !== 0) {
                        return gOrder;
                    }
                    else {
                        // ID first
                        if (a.vehicle.id > b.vehicle.id) {
                            return 1;
                        }
                        else {
                            return -1;
                        }
                    }
                }
                // ID first
                if (a.vehicle.id > b.vehicle.id) {
                    return 1;
                }

                return -1;

            });
        case 'vehicles':
        default:
            return vehicles.sort((a, b) => {
                // Nulls last
                if (a.group == null && b.group != null) {
                    return 1;
                }
                else if (a.group != null && b.group == null) {
                    return -1;
                }
                else if (a.group != null && b.group != null) {
                    // Order by group
                    const gOrder = a.group.localeCompare(b.group);
                    if (gOrder !== 0) {
                        return gOrder;
                    }
                    else {
                        // ID first
                        if (a.id > b.id) {
                            return 1;
                        }
                        else {
                            return -1;
                        }
                    }
                }
                // ID first
                if (a.id > b.id) {
                    return 1;
                }

                return -1;

            });

    }
}

const fuelTypeListLocal = [
    "",
    "Diesel",
    "Kaasu",
    "Sähkö",
    "Vety",
    "Biodiesel",
]

//Get vehicles type from list
const getType = (params) => {
    return logitarVehicleTypes[parseInt(params.value) - 1];
}

const columns = [
    { field: 'id', headerName: 'Kalustonumero', flex: 1 },
    { field: 'licenseNumber', headerName: 'Rekisterinumero', flex: 1 },
    { field: 'costCentre', headerName: 'KustPaikka', flex: 1 },
    { field: 'type', headerName: 'Tyyppi', flex: 1, valueGetter: getType },
    { field: 'name', headerName: 'Selite', flex: 3 },
    { field: 'curbWeight', headerName: 'Omamassa', flex: 1 },
    { field: 'grossWeight', headerName: 'Kokonaispaino', flex: 1 },
    { field: 'inspectionDate', headerName: 'Viime Katsastus', flex: 1 },
    { field: 'inspectionByDate', headerName: 'Katsastus Viimeistään', flex: 1 },
    { field: 'volume', headerName: 'Tilavuus', flex: 1 },
    { field: 'fuelType', headerName: 'Käyttövoima', flex: 1, valueGetter: (params) => fuelTypeListLocal[params.value] },
    { field: 'VIN', headerName: "VIN", flex: 1 },
    { field: 'phoneNumber', headerName: 'Puh. numero', flex: 1 },
    { field: 'group', headerName: 'Ryhmä', disableExport: true },
    {
        field: 'subcontractor',
        headerName: 'Alihankkija',
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params) => (
            <Checkbox
                checked={params.value} 
            />
        ),
    }
]

export default function Vechicles() {
    /** @type {{config: import("../components/persistent/PersistentDataGridPro").UserConfigDataGrid}} */
    const userConfig = useUserConfig('vehicles');

    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [popup, setPopup] = useState(false);
    const [vehicle, setVehicle] = useState({});
    const [groups, setGroups] = useState([]);
    //Count for handling grid update
    const [count, setCount] = useState(0);
    const [createNew, setCreateNew] = useState(false);
    const [filterPassive, setFilterPassive] = useState(userConfig.config.showPassive != null ? !userConfig.config.showPassive : true);
    const [sortHideGroups, setSortHideGroups] = useState(userConfig.config.dataGridInitialState?.sorting?.sortModel ? userConfig.config.dataGridInitialState.sorting.sortModel.length > 0 : false);

    const handleNewVehicle = () => {

        setVehicle({
            type: 1
        })
        setPopup(true)
        setCreateNew(true)
    }

    /**
     * Call vehicles, map vehicles with id added to rows hook
     */
    useEffect(() => {
        LogitarApi.getVehicles({ extent: "all" }).then((result) => {
            if (!result.vehicles) {
                return
            }
            let lastGroup = "";
            let groupId = 9999;
            // Sort by group
            const nveh = vehicleGroupSorter(result.vehicles);

            setRows(nveh.flatMap((e) => {
                if (e.group !== lastGroup) {
                    lastGroup = e.group;
                    groupId++;
                    return [
                        { id: e.group || "Ei ryhmää", isGroup: true },
                        e
                    ];
                }
                else {
                    return [e];
                }
            }));

            // Create group array here to save processing and network in VehicleEdit
            let gs = [];
            for (let g of result.vehicles) {
                if (!g.group)
                    continue;

                if (!gs.includes(g.group)) {
                    gs.push(g.group);
                }
            }
            setGroups(gs);
        })
            .catch((err) => console.error(err))
    }, [count])

    useEffect(() => {
        if (rows.length === 0)
            return;

        if (filterPassive) {
            setFilteredRows(rows.filter(e => e.type !== logitarVehicleTypes.indexOf("Passiivi") + 1 || e.isGroup));
        }
        else {
            console.log(rows);
            setFilteredRows(rows.filter(e => e.type === logitarVehicleTypes.indexOf("Passiivi") + 1 || e.isGroup));
        }
        if (sortHideGroups) {
            setFilteredRows(prev => prev.filter(row => !row.isGroup));
        }
    }, [filterPassive, rows, sortHideGroups])

    //Fetch vehicle information when row selected and open popup
    const handlePopupVehicle = (params) => {

        if (!params.row.id) {
            console.log("Can't open a empty vehicle")
            return
        }

        LogitarApi.getVehicles({ id: params.row.id, extent: "all" }).then((result) => {
            if (result.status) {
                const v = result.vehicles[0];

                ['registrationDate', 'inspectionDate', 'inspectionByDate'].forEach(e => {
                    if (!v[e])
                        v[e] = new Date()
                    else
                        v[e] =  new Date(v[e])
                })

                console.log(v)

                setVehicle(v)
                setPopup(true)
                setCreateNew(false)
                console.log("Fetched: " + result.vehicles[0].id)
            }
        })
            .catch((err) => console.error(err))

    }

    return (
        <Box sx={{
            ...contentBoxStyle, '& .MuiDataGrid-row': {
                cursor: "pointer"
            },
            display: 'flex', flexDirection: 'column', height: '100%', width: '100%'
        }}>
            <PersistentDataGridPro
                userConfig={userConfig}
                rows={filteredRows}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
                disableSelectionOnClick={true}
                disableRowSelectionOnClick={true}
                // columnVisibilityModel={{ // Added disableExport: true in 'group' column instead
                //     group: false
                // }}
                width="100%"
                getRowClassName={(props) => {
                    if (!props.row.isGroup)
                        return "";

                    return "row-default-grey";
                }}
                density='compact'
                sx={{
                    ".group-label .MuiDataGrid-cell": {
                        overflow: 'visible !important',
                        textOverflow: 'unset !important'
                    },
                    ".group-label .MuiDataGrid-cellContent": {
                        overflow: 'visible !important',
                        textOverflow: 'unset !important'
                    },
                    ".group-label:hover": {
                        background: '#888',
                        color: '#FFF',
                        cursor: 'initial'
                    }
                }}
                getRowHeight={(params) => params.model.isGroup ? 24 : 48}
                onRowClick={(params) => {
                    if (params.row.isGroup)
                        return;

                    handlePopupVehicle(params)
                }}
                slots={{
                    toolbar: () => <CustomToolbar
                        userConfig={userConfig}
                        text={"Uusi Kalusto"}
                        onClick={handleNewVehicle}
                        passiveFilter={!filterPassive}
                        onPassiveFilter={(c) => {
                            setFilterPassive(!c);
                            userConfig.config = { ...userConfig.config, showPassive: c };
                        }}
                        fileName={"kalusto"}
                        getRowsToExport={(params) => {
                            return filteredRows.filter(e => !e.isGroup).map(e => e.id)
                        }}
                        hidePrinting
                    />
                }}
                localeText={localeText}
                loading={!rows[0]}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            inspectionDate: false,
                            VIN: false,
                            inspectionByDate: false,
                            volume: false,
                            grossWeight: false,
                            curbWeight: false,
                        },
                    },
                }}
                slotProps={toolbarSlotProps}
                onSortModelChange={(model) => {
                    setSortHideGroups(model.length > 0);
                }}
            />

            {popup &&
                <VehicleEdit
                    tractorUnits={rows.filter(e => e.type == 1).map(e => ({ id: e.id, name: e.name, licenseNumber: e.licenseNumber }))}
                    vehicle={vehicle}
                    groups={groups}
                    onClick={setPopup}
                    new={createNew}
                    count={count}
                    rowUpdate={setCount}
                />
            }
        </Box>
    )
}