import { Backdrop, Box, FormControlLabel, FormGroup, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Tooltip, Typography, createTheme, useTheme } from '@mui/material';
import { addDays, format, set, subDays } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import LogitarApi from '../api/LogitarApi';
import WorkHourRow from '../components/WorkHourRow';
import { DatePick } from '../misc/DatePick';
import { getPayTimeframe, Loader } from '../misc/InternalFeatures';
import ListSelect from '../misc/ListSelect';
import LogiTarUser, { LogiTarUserType } from '../misc/User';
import { contentBoxStyle } from '../styles/styles';
import { Clear } from '@mui/icons-material';
import Config from '../config/Config';

export const whHeaders = [
    { header: "", name: "day" },
    { header: "Pvm", name: "date" },
    { header: "Tulosp.", name: "costCentre" },
    { header: "Työ alku", name: "startTime" },
    { header: "Työ loppu", name: "endTime" },
    { header: "Tunnit", name: "workHours" },
    { header: "Iltatyö", name: "eveningHours" },
    { header: "Yötyö", name: "nightHours" },
    { header: "Tauko (min)", name: "breakHours" },
    { header: "Odotus (min)", name: "waitHours" },
    { header: "Poissaolo", name: "exception" },
    { header: "Päiväraha", name: "dailyAllowance" },
    { header: "Lisätieto", name: "route" }
];

const step = 14;

const tableTheme = createTheme({
    components: {
        MuiTable: {
            styleOverrides: {
                root: {
                    overflow: "hidden",
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    paddingLeft: 4,
                    paddingRight: 4,
                    paddingTop: 4,
                    paddingBottom: 4,
                    borderRadius: 4,
                    "& .MuiTextField-root": {
                        height: 24,
                        width: "100%",
                        input: {
                            height: 18,
                            padding: 4,
                            fontSize: "0.8em"
                        },
                    },
                    "& .MuiSelect-select": {
                        minWidth: 35,
                        padding: 4,
                    },
                    input: {
                        width: "54px"
                    },

                    "& .MuiInputBase-root": {
                        height: 24
                    },
                    "& .MuiButton-root": {
                        height: 24,
                        padding: 4,
                        svg: {
                            height: 14
                        }
                    }
                }
            }
        },
        // MuiTypography: {
        //     styleOverrides: {
        //         root: {
        //             minWidth: 350
        //         }
        //     }
        // },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    marginBottom: 4
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                inputProps: {
                    min: 0,

                }
            }
        },
    }
})

function WorkHours() {

    const [timeFrame, setTimeFrame] = useState(getPayTimeframe());
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [ticks, setTicks] = useState(0);
    const worksRef = useRef();
    const [user, setUser] = useState(LogiTarUser.current.info)
    const [usersLoading, setUsersLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [onlyActiveDrivers, setOnlyActiveDrivers] = useState(true);
    const [showNotDriver, setShowNotDriver] = useState(true);
    const [page, setPage] = useState(0);

    const theme = useTheme();

    const disable = LogiTarUser.current.isDriver();

    const handleButton = (b) => {

        if (!b) {
            setPage(prev => prev - 1)
            return setTimeFrame(prev => ({ from: subDays(prev.from, step), to: subDays(prev.to, step) }))
        }
        setPage(prev => prev + 1)
        setTimeFrame(prev => ({ from: addDays(prev.from, step), to: addDays(prev.to, step) }))
    }

    useEffect(() => {
        if (!disable) {
            setUsersLoading(true);
            LogitarApi.getUsers({ userType: 1 }).then(res => setAllUsers(res.users.sort((a, b) => a.id - b.id)))
                .catch(err => {
                    console.error(err);
                    enqueueSnackbar("Kuljettajien listan lataaminen epäonnistui", { variant: "error" });
                }).finally(() => setUsersLoading(false));
        }
    }, [disable])

    useEffect(() => {

        if (!disable && allUsers.length > 0) {
            if (onlyActiveDrivers) {
                setUsersLoading(true);
                LogitarApi.getWorkHours(timeFrame.from, timeFrame.to).then(res => {
                    const activeUsers = allUsers.filter(user => !user.passive);
                    setUsers(res.workhours.filter(wh => activeUsers.some(u => u.id === wh.user))
                        .sort((a, b) => a.user - b.user).map(wh => ({ ...activeUsers.find(u => u.id === wh.user), id: wh.user, name: wh.name })));
                }).catch(err => {
                    console.error(err);
                    enqueueSnackbar("Kuljettajien listan lataaminen epäonnistui", { variant: "error" });
                }).finally(() => setUsersLoading(false));
            } else {
                setUsers(allUsers);
            }
        }
    }, [onlyActiveDrivers, timeFrame, disable, allUsers])

    useEffect(() => {

        setLoading(() => true)

        if (isNaN(timeFrame.from.getTime()) || isNaN(timeFrame.to.getTime())) {
            setLoading(() => false)
            return
        }
        LogitarApi.getWorkHours(timeFrame.from, timeFrame.to, user.id).then((result) => {

            const workhours = result.workhours.length || null
            console.log(user)

            const whs = []

            const works = [...Array(step)].forEach((e, key) => {

                const date = format(addDays(timeFrame.from, key), "yyyy-MM-dd")

                const existingHours = workhours ? result.workhours[0].workhours.filter((wh) => wh.date === date) : undefined

                // Add existing hours to the whs array, even if there are multiple for the day
                if (existingHours && existingHours.length > 0) {
                    existingHours.forEach((existing, index) => {
                        let notCompleted = {

                        }

                        if (!(existing.startTime && existing.endTime && existing.workHours !== 0) &&
                            !(existing.shortTime || existing.sickHours || existing.vacationHours || existing.holidayHours || existing.paternityLeaveHours)) {
                            notCompleted = {
                                bgcolor: "error.main",
                                color: "white"
                            }
                        }

                        whs.push({
                            ...existing,
                            colorStyle: {
                                ...notCompleted
                            },
                            date: new Date(existing.date),
                            startTime: existing.startTime ? new Date(existing.startTime) : null,
                            endTime: existing.endTime ? new Date(existing.endTime) : null,
                            startTimeMod: existing.startTimeMod ? new Date(existing.startTimeMod) : null,
                            endTimeMod: existing.endTimeMod ? new Date(existing.endTimeMod) : null,
                            startTimeOrig: existing.startTimeOrig ? new Date(existing.startTimeOrig) : null,
                            endTimeOrig: existing.endTimeOrig ? new Date(existing.endTimeOrig) : null,
                        })
                    })
                } else {                    
                    // IF the driver is filling the hours by hand, approximate the shift
                    const shift = LogiTarUser.current.isDriver() ? (new Date().getHours() >= 3 && new Date().getHours() < 15) ? "A" : "I" : null

                    whs.push({
                        colorStyle: {
                            bgcolor: "error.light",
                            color: "white"
                        },
                        date: date,
                        costCentre: LogiTarUser.current.vehicle ? LogiTarUser.current.vehicle.costCentre : 0,
                        startTime: new Date(addDays(timeFrame.from, key).setHours(0, 0, 0, 0)),
                        endTime: null,
                        startTimeMod: null,
                        endTimeMod: null,
                        breakHoursMod: null,
                        endTimeOrig: null,
                        startTimeOrig: null,
                        startTimeModUser: null,
                        endTimeModUser: null,
                        shift: shift,
                        workHours: 0.0,
                        eveningHours: 0.0,
                        nightHours: 0.0,
                        breakHours: Config.getBranch() === "konnekuljetus" ? 0.0 : 0.5,
                        waitHours: 0.0,
                        shortTime: 0.0,
                        sickHours: 0.0,
                        vacationHours: 0.0,
                        holidayHours: 0.0,
                        paternityLeaveHours: 0.0,
                        sundayHours: 0.0,
                        dailyAllowance: 0,
                        route: "",
                        user: user.id,
                        vehicle: LogiTarUser.current.vehicle ? LogiTarUser.current.vehicle.id : null,
                    })
                }
            })

            worksRef.current = whs;

            console.log(worksRef.current)
            setLoading(() => false)
            setTicks(prev => prev + 1)
        })
            .catch(e => {
                console.error(e);
                enqueueSnackbar("Tuntien haku epäonnistui", { variant: "error" })
            })

    }, [timeFrame, user]);

    const handleUserChange = (e, v) => {
        if (!v) return

        setUser(v)
    }

    return (
        <Box sx={contentBoxStyle}>
            <ThemeProvider theme={(theme) => createTheme({ ...theme, components: { ...theme.components, ...tableTheme.components } })}>
                <Backdrop sx={{ zIndex: 100 }} open={loading}>
                    <Loader color={"white"} />
                </Backdrop>

                <FormGroup row>
                    {/**Tools */}
                    <FormGroup sx={{ px: 1 }}>
                        <FormGroup row sx={{ mb: 1, alignItems: "center", gap: 1 }}>
                            {disable ?
                                <Typography>Nimi: {user.name}</Typography> :

                                <FormGroup row sx={{ gap: 1 }}>
                                    <ListSelect
                                        sx={{ width: 350 }}

                                        value={user}
                                        name="user"
                                        label="Kuljettaja"
                                        dataHandle={handleUserChange}
                                        disableClearable
                                        externalOptions={users}
                                        loading={usersLoading}
                                    />
                                    <Tooltip slotProps={{ tooltip: { sx: { width: 175 } } }} title="Näytä vain aktiiviset kuljettajat, joilla on tuntikirjauksia valitulla aikajaksolla">
                                        <FormControlLabel
                                            control={<Switch
                                                checked={onlyActiveDrivers}
                                                onChange={(e) => setOnlyActiveDrivers(e.target.checked)}
                                            />}
                                            label="Vain aktiiviset" />
                                    </Tooltip>
                                </FormGroup>
                            }
                            {
                                (user.userType && (user.userType === LogiTarUserType.DRIVER || user.userType === LogiTarUserType.CONTRACTOR)) &&
                                <Typography sx={{ width: 350 }} >Kuljettaja Nro: {user.id}</Typography>
                            }
                        </FormGroup>
                        <DatePick date={timeFrame} buttonSet={handleButton} dateSet={setTimeFrame} disabled={true} ></DatePick>
                    </FormGroup>

                    {/**Tables etc */}

                    <TableContainer component={Paper} sx={{ position: "relative" }}>
                        {
                            (showNotDriver && !loading && user.userType && user.userType !== LogiTarUserType.DRIVER && user.userType !== LogiTarUserType.CONTRACTOR) &&
                            <Box sx={{
                                position: "absolute", left: 0, right: 0, top: 0, bottom: 0,
                                backgroundColor: `${theme.palette.background.default}bb`, zIndex: 1,
                                display: "flex", justifyContent: "center", alignItems: "center",
                            }}>
                                <Typography variant='h5'>Valitse ensin kuljettaja</Typography>
                                <IconButton sx={{ position: "absolute", top: 4, right: 4 }} onClick={() => setShowNotDriver(false)}>
                                    <Clear />
                                </IconButton>
                            </Box>
                        }
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {whHeaders.map((field, key) => (
                                        <TableCell key={key}>
                                            {field.header}
                                        </TableCell>
                                    ))}
                                    <TableCell />
                                    <TableCell />

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {worksRef.current && worksRef.current.map((wt, key) => {
                                    return (
                                        <WorkHourRow
                                            key={key}
                                            divide={(new Date(wt.date).getDay() === 0 && key !== worksRef.current.length - 1)}
                                            wt={wt}
                                            ticks={ticks}
                                            user={user}
                                            disable={
                                                ((Boolean(Number(user.allowHoursWrite)) && (page > 0 || page < -1)) || 
                                                (!Boolean(Number(user.allowHoursWrite)) && page !== 0)) && disable
                                            }
                                        />
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </FormGroup>
            </ThemeProvider>
        </Box>
    )
}

export default WorkHours;